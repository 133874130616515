body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.7em !important;
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
}

#root {
  width: 100%;
  position: relative;
  display: flex;
}


/* 
.timeline {
  width: 100%;
  flex: 1 0 100px;
} */

.entry-list-page .card {
  padding-bottom: 2em !important;
  margin-bottom: 6em !important;
}

.content-header {
  padding: 1em !important;
  margin-bottom: 0 !important;
}


/* Images, docs, PDFs, videos */

.archive-page .content img,
.entry-list-page .content img,
.entry-page .content img {
  max-width: 100%;
}

.entry-list-page .media,
.entry-page .media {
  display: flex;
  /* justify-content: center; */
  /* align-items: flex-start; */
  flex-direction: column;
}

.media-item {
  padding: 1em;
  position: relative;
}

.react-pdf__Page__canvas {}

.react-pdf__message {
  margin: 1em;
}

.media-container {
  width: 100%;
}

.doc-container:not(:last-child) {
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.pdf-container {
  outline: 1px solid #666;
  padding-bottom: 1em;
}

.pdf-container:not(:last-child) {
  outline: 1px solid #666;
  margin-bottom: 1em;
}

.pdf-tools {
  margin: 0.4em 1em 1em 1em;
}

.pdf-download {
  padding: 1em;
}


/* compensate for fixed menu */

body {
  padding-top: 80px !important;
}

.fla-logo {
  height: 60px;
  margin: 0 1em;
}

@media only screen and (max-width: 400px) {
  .fla-logo {
    height: 32px;
    margin: 0 0.2em;
  }
}

.home-page {
  margin: 0;
  width: 100%;
}

.splash {
  text-align: center;
  background-color: pink;
  padding: 4em;
  background-size: cover;
  background-position: center center;
  background-image: url(/flowers.jpg);
  color: #fff;
}

.home-page-content {
  margin: 50px 0;
}

.home-links {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
}

.home-link {
  font-size: 2em;
  margin: 1em 0;
}

a,
a:visited {
  color: rgb(158, 55, 38) !important
}

hr {
  background-color: rgba(158, 55, 38, 0.4);
  border: 0 none;
  color: rgba(158, 55, 38, 0.4);
  height: 1px !important;
}

hr.padded {
  margin: 4em;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@media only screen and (max-width: 767px) {
  .ui.container.entry-list-page,
  .ui.container.entry-page {
    width: auto!important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}


/* for iframes in content */

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-container-43 {
  position: relative;
  overflow: hidden;
  padding-top: 70%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.content.meta ul {
  list-style-type: none;
}

.entry-list-page .content.meta ul li {
  display: inline;
}

.entry-list-page .content.meta ul li:not(:last-child):after {
  content: " . "
}

.list-tools {
  display: flex;
  justify-content: space-between;
}

.date {
  font-weight: bold;
}

.ui.container.timeline-page {
  flex: 1 0 100px;
  display: flex;
  background-image: url(/spinner.gif);
  background-repeat: no-repeat;
  background-position: center center;
}

.archive-page .content {
  margin-bottom: 1em;
}

.archive-page .links a {
  padding: 0.5em 0;
  margin: 0.5em 0;
  border-top: 1px solid #eee;
}